export default {
    SEM_VINCULO: "Não possuo vínculo com a UMC",
    ALUNO_GRAD: "Aluno",
    ALUNO_POS: "Aluno Pós",
    ALUNO_TEC: "Aluno Técnico",
    EX_ALUNO: "Ex-Aluno",
    EX_ALUPOSG: "Ex-Aluno Pós",
    EX_ALUTEC: "Ex-Aluno Técnico",
    ALUNO_CONV: "Convênio Empresa",
    FUNC: "Funcionário",
}