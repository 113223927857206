import { createStore } from 'vuex'
import { ModuloCommon } from "@/store/Common.js"
import { ModuloCurso } from "@/store/Curso.js"
import { ModuloPessoa } from "@/store/Pessoa.js"
import { ModuloPessoaComplemento } from './PessoaComplemento.js'
import axios from 'axios'

const helperMethods = {
  firstUpperCase: (text) => {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
    return ""
  },
}

export default createStore({
  state: {
    showSpinner: false,
    campusPolo: {},
    estadosCivis: {},
    nacionalidades: {},
    paises: {},
    origensAluno: {},
    urlCep: "/POSGRAD/pos/inscricao/cep/",
    urlCidades: "/POSGRAD/pos/inscricao/cidades/",
    racas: ["Branca", "Preta", "Parda", "Amarela", "Indígena", "Não declarada", "Não dispõe da informação"],
    respostaInscricao: {}
  },
  getters: {
    contrato(state){
      if (state.common.categorias[state.common.currentCategoria].nivel["EXT"]  && !state.common.categorias[state.common.currentCategoria].gratuito) {
        return ''
      } else if (state.common.categorias[state.common.currentCategoria].nivel["1"]) {
        return ''
      }
    },
    showSpinner(state) {
      return state.showSpinner;
    },
    getRespostaInscricao(state) {
      return state.respostaInscricao
    },
    getCampusPolo(state) {
      return state.campusPolo
    },
    getEstadosCivis(state) {
      return state.estadosCivis
    },
    getNacionalidades(state) {
      return state.nacionalidades
    },
    getPaises(state) {
      return state.paises
    },
    getOrigensAluno(state) {
      return state.origensAluno
    },
    sexos(state) {
      return state.sexos
    },
    racas(state) {
      return state.racas
    },
    urlCep(state) {
      return state.urlCep
    },
    urlCidades(state) {
      return state.urlCidades
    },
  },
  mutations: {
    setShowSpinner(state, showSpinner) {
      state.showSpinner = showSpinner
    },
    setRespostaInscricao(state, respostaInscricao) {
      //console.log("Resposta Inscricao: ", respostaInscricao)
      state.respostaInscricao = respostaInscricao
    },
    setCampusPolo(state, campusPolo) {
      //console.log("insert: ", campusPolo);
      if (state.common.categorias[state.common.currentCategoria].reg_id === "P") {
        state.campusPolo = { MC: "MOGI DAS CRUZES" }
      } else if (state.common.categorias[state.common.currentCategoria].reg_id === "EAD") {
        state.campusPolo = campusPolo.reduce(function (objeto, index) {
          if(index.cod_polo_ead===11){
            objeto[' ' + index.cod_polo_ead] = index.NOM_FANTASIA;
          }
          return objeto;
        }, {});
      }
      //console.log("insert: ", campusPolo);
      //console.log("state.campusPolo: ", state.campusPolo);
      
    },
    setEstadosCivis(state, estadosCivis) {
      state.estadosCivis = estadosCivis.reduce(function (objeto, index) {
        objeto[index.sigla] = index.descricao;
        return objeto;
      }, {});
    },
    setNacionalidades(state, nacionalidades) {
      state.nacionalidades = nacionalidades.reduce(function (objeto, index) {
        objeto[' ' + index.valor.substring(0, 2)] = helperMethods.firstUpperCase(index.nome);
        return objeto;
      }, {});
    },
    setPaises(state, paises) {
      state.paises = paises.reduce(function (objeto, index) {
        objeto[' ' + index.valor.substring(0, 2)] = index.nome;
        return objeto;
      }, {});
    },
    setOrigensAluno(state, origensAluno) {
      state.origensAluno = origensAluno.reduce(function (objeto, index) {
        objeto[index.id] = index.nome;
        return objeto;
      }, {});
    },
  },
  actions: {
    async loadObjects(context) {
      let links = [
        `${this.state.common.server}/POSGRAD/pos/inscricao/detalhesCurso/poloEad`,
        `${this.state.common.server}/POSGRAD/pos/inscricao/estadosCivis`,
        `${this.state.common.server}/POSGRAD/pos/inscricao/nacionalidades`,
        `${this.state.common.server}/POSGRAD/pos/inscricao/paises`,
        `${this.state.common.server}/POSGRAD/pos/inscricao/conheceuUmc`,
      ];

      let commits = [
        "setCampusPolo",
        "setEstadosCivis",
        "setNacionalidades",
        "setPaises",
        "setOrigensAluno",
      ];

      for (let i = 0; i < links.length; i++) {
        await axios
          .get(links[i])
          .then((response) => {
            if (response.status == 200) {
              this.commit(commits[i], response.data);
            } else {
              console.log(error)
              console.log(error.message);
            }
          })
          .catch((error) => {
            console.log(error)
            console.log(error.message);
          })
          .finally(() => { });
      }
    },
    async doRequest(context, requestData) {
      if (!requestData) {
        return;
      }
      this.state.showSpinner = true;
      await axios.get(requestData.url)
        .then(response => {
          if (response.status == 200) {
            requestData.doAfter(response.data);
            this.state.showSpinner = false;
          } else {
            this.state.showSpinner = false;
            requestData.doAfter(null);
            console.log(error.message);
          }
        }).catch(error => {
          this.state.showSpinner = false;
          requestData.doAfter(null);
          console.log(error.message)
        })
        .finally(() => {
          this.state.showSpinner = false;
        });
    }
  },
  modules: {
    common: ModuloCommon,
    curso: ModuloCurso,
    pessoa: ModuloPessoa,
    pessoaComplemento: ModuloPessoaComplemento
  }
})
