<template>
  <div class="container">
    <div class="row">
      <span class="required col sm-6">(*) Campos obrigatórios</span>
      <span class="col-sm-6 obs">(*) Somente para localidades não atendidas pelos correios</span>
    </div>

    <div class="container shadow-lg p-3 mb-3 rounded border border-secondary">
      <div class="row">
        <div class="form-group col-sm-12">
          <label for="nome_social">Nome Social</label>
          <input class="form-control" name="nome_social" id="nome_social" v-model="nome_social" maxlength="60" v-autofocus />
        </div>
      </div>

      <hr class="bg-danger border-2 border-top border-secondary" />

      <div class="row">
        <div class="form-group col-sm-2">
          <label for="sexo">Sexo<span class="required">*</span><ErrorMsg :errors="v$.sexo.$errors"/></label>
          <select class="form-control form-select" name="sexo" id="sexo" v-model="sexo" ref="sexo">
            <option value="" selected>Selecione</option>
            <option v-for="(name, key) in sexos" :value="key" v-text="name" :key="key"></option>
          </select>
        </div>

        <div class="form-group col-sm-2">
          <label for="racaCor">Cor / Raça<span class="required">*</span><ErrorMsg :errors="v$.racaCor.$errors"/></label>
          <select name="racaCor" id="racaCor" class="form-control form-select" v-model="racaCor" ref="racaCor">
            <option value="" selected>Selecione</option>
            <option v-for="raca in racas" :value="raca" v-text="raca" :key="raca"></option>
          </select>
        </div>

        <div class="form-group col-sm-2">
          <label for="datnasc">Data de Nascimento<span class="required">*</span><ErrorMsg :errors="v$.datnasc.$errors"/></label>
          <input class="form-control" type="tel" name="datnasc" id="datnasc" v-mask="'##/##/####'"  placeholder="__/__/____" v-model="datnasc" ref="datnasc"/>
        </div>

        <div class="form-group col-sm-2">
          <label for="estadoCivil">Estado Civíl<span class="required">*</span><ErrorMsg :errors="v$.estadoCivil.$errors"/></label>
          <select class="form-control form-select" name="estadoCivil" id="estadoCivil" v-model="estadoCivil" ref="estadoCivil">
            <option value="" selected>Selecione</option>
            <option v-for="(name, key) in estadosCivis" :value="key" v-text="name" :key="key"></option>
          </select>
        </div>

        <div class="form-group col-sm-4">
          <label for="profissao">Profissão</label>
          <input class="form-control" name="profissao" id="profissao" v-model="profissao" maxlength="100"/>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-2">
          <label for="nacionalidade">Nacionalidade<span class="required">*</span><ErrorMsg :errors="v$.nacionalidade.$errors"/></label>
          <select class="form-control form-select" name="nacionalidade" id="nacionalidade" v-model="nacionalidade" ref="nacionalidade">
            <option value="" selected>Selecione</option>
            <option v-for="(name, key) in nacionalidades" :value="key" v-text="name" :key="key"></option>
          </select>
        </div>

        <div class="form-group col-sm-2">
          <label for="pais_orig">País de Origem<span class="required">*</span><ErrorMsg :errors="v$.pais_orig.$errors"/></label>
          <select class="form-control form-select" name="pais_orig" id="pais_orig" v-model="pais_orig" ref="pais_orig">
            <option value="" selected>Selecione</option>
            <option v-for="(nome, key) in paises" :value="key" v-text="nome" :key="key"></option>
          </select>
        </div>

        <div class="form-group col-sm-2">
          <div v-if="origemBrasil">
            <label for="estadoOrigemBrasil">Estado de Origem<span class="required">*</span><ErrorMsg :errors="v$.estadoOrigemBrasil.$errors"/></label>
            <select class="form-control form-select" name="estadoOrigemBrasil" id="estadoOrigemBrasil" v-model="estadoOrigemBrasil" ref="estadoOrigemBrasil">
              <option value="" selected>Selecione</option>
              <option v-for="(name, key) in ufs" :value="key" v-text="name" :key="key"></option>
              <option value="OU" v-show="origemExterior">OUTRO</option>
            </select>
          </div>

          <div v-if="origemExterior">
            <label for="estadoOrigemExterior">Estado de Origem<span class="required">*</span><ErrorMsg :errors="v$.estadoOrigemExterior.$errors"/></label>
            <input class="form-control" name="estadoOrigemExterior" id="estadoOrigemExterior" maxlength="60" v-model="estadoOrigemExterior" ref="estadoOrigemExterior"/>
          </div>
        </div>

        <div class="form-group col-sm-3">
          <div v-if="origemBrasil">
            <label for="cidadeOrigemBrasilCodigo">Cidade de Origem<span class="required">*</span><ErrorMsg :errors="v$.cidadeOrigemBrasilCodigo.$errors"/></label>
            <select class="form-control form-select" name="cidadeOrigemBrasilCodigo" id="cidadeOrigemBrasilCodigo" v-model="cidadeOrigemBrasilCodigo" ref="cidadeOrigemBrasilCodigo">
              <option value="" selected>Selecione</option>
              <option v-for="(name, key) in cidades" :value="key" v-text="name" :key="key"></option>
            </select>
          </div>
          <div v-if="origemExterior">
            <label for="cidadeOrigemExterior" >Cidade de Origem<span class="required">*</span><ErrorMsg :errors="v$.cidadeOrigemExterior.$errors"/></label>
            <input class="form-control" name="cidadeOrigemExterior" id="cidadeOrigemExterior" v-model="cidadeOrigemExterior" maxlength="60" ref="cidadeOrigemExterior"/>
          </div>
        </div>
        <div class="form-group col-sm-3">
          <label for="caixaPostal" >Caixa Postal<span class="obs">(*)</span></label>
          <input class="form-control" name="caixaPostal" id="caixaPostal" v-model="caixaPostal" maxlength="5"/>
        </div>
      </div>

      <hr class="bg-danger border-2 border-top border-secondary" />

      <div class="row">
        <div class="form-group col-sm-2">
          <label for="cep">CEP<span class="required">*</span><ErrorMsg :errors="v$.cep.$errors"/></label>
          <input type="tel" class="form-control"  name="cep" id="cep" @blur="findAddress"  v-model="cep" maxlength="10" masked="true" v-mask="'#####-###'" placeholder="_____-___" ref="cep"/>

          
        </div>

        <div class="form-group col-sm-6">
          <label for="endereco">Endereço<span class="required">*</span><ErrorMsg :errors="v$.endereco.$errors"/></label>
          <input class="form-control"  name="endereco" id="endereco" maxlength="50" v-model="endereco" ref="endereco"/>
        </div>

        <div class="form-group col-sm-2">
          <label for="numero">Número<span class="required">*</span><ErrorMsg :errors="v$.numero.$errors"/></label>
          <input class="form-control" name="numero" id="numero" maxlength="6" v-model="numero" ref="numero"/>
        </div>

        <div class="form-group col-sm-2">
          <label class="form-label" for="endcompl">Complemento</label>
          <input class="form-control" name="endcompl" id="endcompl" maxlength="20" v-model="endcompl"/>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-4">
          <label for="bairro">Bairro<span class="required">*</span><ErrorMsg :errors="v$.bairro.$errors"/></label>
          <input class="form-control"  name="bairro" id="bairro" maxlength="30" v-model="bairro" ref="bairro"/>
        </div>

        <div class="form-group col-sm-4">
          <label for="cidade_end">Cidade<span class="required">*</span><ErrorMsg :errors="v$.cidade_end.$errors"/></label>
          <input class="form-control" name="cidade_end" id="cidade_end" readonly maxlength="60" v-model="cidade_end" ref="cidade_end"/>
        </div>

        <div class="form-group col-sm-4">
          <label for="estado_end">Estado<span class="required">*</span><ErrorMsg :errors="v$.estado_end.$errors"/></label>
          <select class="form-control form-select" name="estado_end" id="estado_end" v-model="estado_end" ref="estado_end">
            <option value="" selected readonly>Selecione</option>
            <option v-for="(name, key) in ufs" :value="key" v-text="name" :key="key"></option>
          </select>
        </div>
      </div>
    </div>

    <div class="container shadow-lg p-3 mb-3 rounded border border-secondary">
      <div class="row">
        <div class="form-group col-sm-12">
          <label for="empresa">Empresa<span class="empresaObs">(Não será utilizado para correspondência)</span></label>
          <input class="form-control" name="empresa" id="empresa" v-model="empresa" maxlength="60"/>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-2">
          <label for="cep_empresa">CEP</label>
          <input class="form-control" type="tel" name="cep_empresa" id="cep_empresa" v-model="cep_empresa" @blur="findAddressCompany" v-mask="'#####-###'" placeholder="_____-___"/>
        </div>

        <div class="form-group col-sm-6">
          <label for="endereco_empresa">Endereço</label>
          <input class="form-control" name="endereco_empresa" id="endereco_empresa" maxlength="60" v-model="endereco_empresa"/>
        </div>
        <div class="form-group col-sm-2">
          <label for="numero_empresa">Número</label>
          <input class="form-control" name="numero_empresa" id="numero_empresa" maxlength="4" v-model="numero_empresa"/>
        </div>
        <div class="form-group col-sm-2">
          <label for="endcompl_empresa">Complemento</label>
          <input class="form-control" name="endcompl_empresa" id="endcompl_empresa" maxlength="20" v-model="endcompl_empresa"/>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-4">
          <label for="bairro_empresa">Bairro</label>
          <input class="form-control" name="bairro_empresa" id="bairro_empresa" maxlength="30" v-model="bairro_empresa"/>
        </div>
        <div class="form-group col-sm-4">
          <label for="cidade_empresa">Cidade</label>
          <input class="form-control" name="cidade_empresa" id="cidade_empresa" readonly maxlength="30" v-model="cidade_empresa"/>
        </div>
        <div class="form-group col-sm-4">
          <label for="uf_empresa">Estado</label>
          <select class="form-control form-select" name="uf_empresa" id="uf_empresa" v-model="ufEmpresa" readonly>
            <option value="" selected>Selecione</option>
            <option v-for="(name, key) in ufs" :value="key" v-text="name" :key="key"></option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-1">
          <label for="ddd_empresa">DDD</label>
          <input class="form-control" type="tel" name="ddd_empresa" id="ddd_empresa" v-model="ddd_empresa" masked="true" v-mask="'##'" placeholder="__"/>
        </div>
        <div class="form-group col-sm-2">
          <label for="telefone_empresa">Telefone</label>
          <input class="form-control" type="tel" name="telefone_empresa" id="telefone_empresa" v-model="telefone_empresa" masked="true" v-mask="'####-####'" placeholder="____-____"/>
        </div>
        <div class="form-group col-sm-1">
          <label for="ramal_empresa">Ramal</label>
          <input class="form-control" name="ramal_empresa" id="ramal_empresa" maxlength="4" v-model="ramal_empresa"/>
        </div>
      </div>
    </div>

    <div class="container shadow-lg p-3 mb-3 rounded border border-secondary">
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="email_alt">E-mail alternativo</label>
          <input name="email_alt" id="email_alt" v-model="email_alt" class="form-control" maxlength="255"/>
        </div>
        <div class="form-group col-sm-6">
          <label for="origem_aluno">Como ficou sabendo da UMC:<span class="required">*</span><ErrorMsg :errors="v$.ficouSabendo.$errors"/></label>
          <select name="origem_aluno" id="origem_aluno" class="form-control form-select" v-model="ficouSabendo" ref="ficouSabendo">
            <option value="" selected>Selecione</option>
            <option v-for="(name, key) in origensAluno" :value="key" v-text="name" :key="key"></option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import sexos_ from '@/common/sexos.js'
import { mapGetters, mapMutations, mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import ErrorMsg from "@/components/ErrorMsg.vue";
import { PessoaComplementoValidation } from "@/common/validation/PessoaComplementoValidation.js";
const CODIGO_BRASIL = " 01"
export default {
  name: "PessoaComplemento",
  components: {
    ErrorMsg,
  },
  data() {
    return {
      sexos: sexos_,
      v$: useValidate(),
      cidades:{},
      origemBrasil: true,
      origemExterior: false,
      telefone_empresa:"",
      ramal_empresa:"",
      ddd_empresa:"",
      empresa:"",
      profissao:"",
      sexo: "",
      racaCor: "",
      datnasc: "",
      estadoCivil: "",
      nacionalidade: CODIGO_BRASIL,
      pais_orig: CODIGO_BRASIL,
      cidadeNasc: "",
      cep: "",
      nome_social: "",
      endereco: "",
      numero: "",
      endcompl: "",
      bairro: "",
      cidade_end: "",
      estado_end: "",
      cep_empresa: "",
      endereco_empresa: "",
      numero_empresa: "",
      endcompl_empresa: "",
      bairro_empresa: "",
      cidade_empresa: "",
      ufEmpresa: "",
      municNasci: "",
      ficouSabendo: "",
      nomeCidade:"",
      email_alt:"",
      caixaPostal:"",
      estadoOrigemBrasil: "",
      estadoOrigemExterior: "",
      cidadeOrigemBrasilCodigo:"",
      cidadeOrigemExterior:""
    };
  },
  validations() {
    return {
      ...PessoaComplementoValidation(),
    };
  },
  watch: {
    pais_orig(pais_origem) {
      this.origemBrasil = pais_origem === CODIGO_BRASIL;
      this.origemExterior = pais_origem !== CODIGO_BRASIL;

      this.estadoOrigemExterior = "";
      this.cidadeOrigemExterior = "";

      this.estadoOrigemBrasil = "";
      this.cidadeOrigemBrasilCodigo = "";
      this.cidades = null;
      
    },
    estadoOrigemBrasil(uf) {
      this.cidadeOrigemBrasilCodigo=""
      this.cidades = null;

      if(uf){
        this.buscaMunicipios(uf);
        this.cidadeOrigemBrasilCodigo = ""
      }

    },
  },
  computed: {
    ...mapGetters({
      racas: "racas",
      urlCep: "urlCep",
      urlCidades: "urlCidades",
      estadosCivis: "getEstadosCivis",
      nacionalidades: "getNacionalidades",
      paises: "getPaises",
      origensAluno: "getOrigensAluno",
      ufs: "ufs"
    }),
  },
  expose: ["fieldsAreValid", "clearFields"],
  methods: {
    ...mapActions({doRequest:"doRequest"}),
    ...mapMutations({
      setCompl:"setCompl"
    }
    ),
    fieldsAreValid(toNextPosition) {
      this.v$.$validate();
      let hasError = this.v$.$error;
      let errors = this.v$.$errors

      if(errors.length === 2){
        if (!(this.$refs[errors[0].$property] && this.$refs[errors[1].$property])){
          hasError = false;
        }
        console.log(this.$refs[errors[0].$property])
        console.log(this.$refs[errors[1].$property])
      }

      if (hasError) {
        for(let i = 0 ; i < errors.length; i++){
          let error = errors[i]

          if(this.$refs[error.$property]){
            let message = error.$message 
            if(error.$property){
              if(this.$refs[error.$property]){
                this.$refs[error.$property].focus()
                this.$toast.error(message, {position: "top",});
                break
              }
            }
          }
        }
      }else{
        var estadoOrigem;
        var cidadeOrigem;

        if(this.estadoOrigemBrasil){
          estadoOrigem = this.estadoOrigemBrasil
        }else if(this.estadoOrigemExterior){
          estadoOrigem = this.estadoOrigemExterior
        }

        if(this.cidadeOrigemExterior){
          cidadeOrigem = this.cidadeOrigemExterior
        }else if(this.cidadeOrigemBrasilCodigo.trim()){
          cidadeOrigem = this.cidadeOrigemBrasilCodigo
        }

        var municNasci_
        var cep_cidade_nasc_
        
        
        if (this.cidades){
          municNasci_ = ''//cidadeOrigem
          cep_cidade_nasc_ = cidadeOrigem
        } else {
          municNasci_ = cidadeOrigem
          cep_cidade_nasc_ = 0//cidadeOrigem
        }
        this.setCompl({
          nome_social: this.nome_social,
          sexo: this.sexo,
          racaCor: this.racaCor,
          datnasc: this.datnasc,
          estadoCivil: this.estadoCivil,
          profissao: this.profissao,
          nacionalidade: this.nacionalidade,
          pais_orig: this.pais_orig,
          ufNascimento: estadoOrigem,
          municNasci: municNasci_,
          cep_cidade_nasc: cep_cidade_nasc_,
          caixaPostal: this.caixaPostal,
          cep: this.cep,
          endereco: this.endereco,
          numero: this.numero,
          endcompl: this.endcompl,
          bairro: this.bairro,
          cidade_end: this.cidade_end,
          estado_end: this.estado_end,
          
          empresa:this.empresa,
          cep_empresa: this.cep_empresa,
          endereco_empresa: this.endereco_empresa,
          numero_empresa: this.numero_empresa,
          endcompl_empresa: this.endcompl_empresa,
          bairro_empresa: this.bairro_empresa,
          cidade_empresa: this.cidade_empresa,
          ufEmpresa: this.ufEmpresa,
          
          ddd_empresa:this.ddd_empresa,
          telefone_empresa:this.telefone_empresa,
          ramal_empresa:this.ramal_empresa,

          cidadeNasc: this.cidadeNasc,
          

          email_alt: this.email_alt,
          ficouSabendo: this.ficouSabendo,
        });
        toNextPosition();
      }
    },
    clearFields() {},
    populateAddress(address) {
      var enderecoCorreio = "";
      if (address) {
        enderecoCorreio = address[0];
      }

      this.endereco = "";
      this.bairro = "";
      this.cidade_end = "";
      this.estado_end = "";

      if (enderecoCorreio) {
        this.endereco = enderecoCorreio.logradouro;
        this.bairro = enderecoCorreio.bairro;
        this.cidade_end = enderecoCorreio.cidade;
        this.estado_end = enderecoCorreio.uf;
      }else{
        this.$toast.error("CEP não encontrado.");
      }
    },
    populateAddressCompany(address) {
      var enderecoCorreio = "";

      if (address) {
        enderecoCorreio = address[0];
      }

      this.endereco_empresa = "";
      this.bairro_empresa = "";
      this.cidade_empresa = "";
      this.ufEmpresa = "";

      if (enderecoCorreio) {
        this.endereco_empresa = enderecoCorreio.logradouro;
        this.bairro_empresa = enderecoCorreio.bairro;
        this.cidade_empresa = enderecoCorreio.cidade;
        this.ufEmpresa = enderecoCorreio.uf;
      }else{
        this.$toast.error("CEP não encontrado.");
      }
    },
    findAddress(event) {
      var cep = event.currentTarget.value;
      cep = cep.replace("-", "");
      var url = `${this.urlCep}${cep}`;
      var parametrosRequisicao = this.dataToRequest(url, this.populateAddress);
      this.doRequest(parametrosRequisicao);
    },
    findAddressCompany(event) {
      var cep = event.currentTarget.value;
      cep = cep.replace("-", "");
      var url = `${this.urlCep}${cep}`;
      var parametrosRequisicao = this.dataToRequest(
        url,
        this.populateAddressCompany
      );
      this.doRequest(parametrosRequisicao);
    },
    populateCities(cities) {
      this.cidades = null;
      if (cities) {
        this.cidades = cities.reduce(function(objeto, index) {
          objeto[' ' + index.cepCidade] = index.nomCidade;
          return objeto;
        }, {});
      }
    },
    buscaMunicipios(uf) {
      if (uf) {
        var url = `${this.urlCidades}${uf}`;
        var parametrosRequisicao = this.dataToRequest(url, this.populateCities);
        parametrosRequisicao;
        this.doRequest(parametrosRequisicao);
      }
    },
  },
};
</script>

<style scoped>
.required {
  color: red;
  margin-left: 2px;
}
.obs {
  margin-left: 2px;
  color: blue;
}
label {
  display: block;
  text-align: left;
}
.form-group {
  margin-bottom: 0.5rem;
}

.dados-pessoais {
  padding: 5px;
  margin: 2px;
}

.empresaObs {
  color: blue;
}
</style>
