<template>
  <div>
    <div class="home">
    <div :class="[flag]"></div>
    <div class="container-fluid">
      <div class="header hidden-xs"></div>
      <div class="container">
        <div class="tituloForm rounded border-bottom">
          <h1>Requerimento de Pré-Matrícula</h1>
          <h2 v-text="title"></h2>
          <h3 v-text="titleSemestre"></h3>
        </div>
      </div>
    </div>
    <keep-alive>
      <component ref="formSelected" :is="currentButtonState.component" />
    </keep-alive>
    <div class="container justify-content-center p-2">    
      <button type="button" @click="navigation_previous" :disabled="currentButtonState.left.disabled" v-text="currentButtonState.left.text" :class="buttonClass"></button>
      <button type="button" @click="navigation_next" :disabled="currentButtonState.right.disabled" v-text="currentButtonState.right.text" :class="buttonClass"></button>
    </div>
    </div>
    <div class="div_rodape mt-3">
      <span class="rodape" v-text="'Copyright © 2023 UMC - Universidade de Mogi das Cruzes. Todos os direitos reservados.'"> </span>
    </div>
  </div>
</template>

<script>
import Pessoa from "@/components/Pessoa.vue";
import PessoaComplemento from "@/components/PessoaComplemento.vue";
import Curso from "@/components/Curso.vue";
import Confere from "@/components/Confere.vue";
import Confirma from "@/components/Confirma.vue";
import Nav from "@/common/Nav.js";
import NavButton from "@/common/NavButton.js";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HomeView",
  computed: {
    ...mapGetters({
      categoria: "categoria",
    }),
  },
  created() {
    Nav.setLength(NavButton.length);
    this.currentButtonState = NavButton[Nav.getCurrent()];
  },
  components: {
    Pessoa,
    PessoaComplemento,
    Curso,
    Confere,
    Confirma,
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)

    this.title = `${this.categoria.description} ${this.categoria.ano}`;
    this.titleSemestre = `${this.categoria.semestre}º semestre`;
    this.ano = this.categoria.ano;
    this.flag = this.categoria.flag;

    if (this.categoria) {
      document.title = this.title;
    }

    this.loadObjects();
  },
  methods: {
    handleResize() {
      this.buttonClass = window.matchMedia('(max-width: 768px)').matches
      ? 'btn btn-primary col col-4 mx-1' : 'btn btn-primary col col-2 mx-1'
    },
    ...mapActions({ loadObjects: "loadObjects" }),
    navigation_next() {
      this.$refs.formSelected.fieldsAreValid(this.toNextPosition);
    },
    navigation_previous() {
      this.$refs.formSelected.clearFields();
      Nav.previous();
      this.setPosition();
    },
    setPosition() {
      this.currentButtonState = NavButton[Nav.getCurrent()];
    },
    toNextPosition() {
      Nav.next();
      this.setPosition();
    },
  },
  data() {
    return {
      buttonClass: "btn btn-primary col col-4 mx-1",
      ano: "",
      flag: "",
      currentButtonState: {},
      title: "",
      titleSemestre: "",
      imgLogo: require("@/assets/logo.png"),
      imgCaixa: require("@/assets/caixa.png"),
    };
  },
};
</script>
<style>
.tituloForm {
  display: block;
  text-align: left;
  background: rgb(255, 255, 255);

  margin: 0.3vw;
  padding-top: 1vw;

  padding: 0.3vw;
}

.pos {
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("@/assets/headerimbutido.png");
  padding-top: calc(100% / (1419 / 164));
  background-image: 100% 100%;
}

.prof {
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("@/assets/headerembutido-prof.png");
  padding-top: calc(100% / (1600 / 169));
  background-image: 100% 100%;
}

.adj {
  border-bottom: 1px dashed #5d4b4b29;
  margin-top: 0px !important;
  padding-bottom: 13px;
  padding-top: 17px;
}
.pb {
  padding-top: 20px;
}
.bn {
  border-bottom: 0px !important;
}
.col-sm-6 {
  top: 25%;
}
.col-sm-3 {
  top: 25%;
}
.col-sm-12 {
  top: 25%;
}
.fltR {
  float: right;
}
.pad {
  padding: 15px 15px 15px 15px;
}
div.container-fluid.main {
  padding-left: 0;
  padding-right: 0;
}
/*div.container-fluid div.header {
  height: 180px;
  overflow: auto;
}*/

div.container-fluid.main .container {
  margin-top: 4%;
}
div.main div.container h1 {
  font-family: monospace; /*solkicitar fonte */
  position: relative;
  transform: translateY(-50%);
  font-size: 31px;
  font-weight: 900;
  color: #40474d;
  margin-top: 0;
}

div.main div.container .caixa {
  position: relative;
  transform: translateY(-50%);
  font-size: 20px;
  font-weight: 900;
  display: inline-block;
  width: 65px;
  /*float: left;*/
}

div.main div.container .logoUmc {
  position: relative;
  width: 50%;
  margin-left: 25%;
  margin-top: -15%;
  margin-bottom: 10%;
}

div.container-fluid.main .container.box {
  margin-top: 1.5%;
}
div.container-fluid.main .container.box h2 {
  font-family: arial; /*solkicitar fonte */
  position: relative;
  font-size: 30px;
}
div.container-fluid.main .container.box .alert {
  padding: 0;
  color: #ff0000;
}
div.container-fluid.main .container.box .row {
  margin-top: 2%;
}
div.container-fluid.main .container.box .row label {
  font-size: 15px;
  margin-left: 10px;
  color: #696e73;
  width: 100%;
}
div.container-fluid.main .container.box .row .fone {
  width: 75%;
}
div.container-fluid.main .container.box .row .ddd {
  width: 25%;
  float: left;
}

div.container-fluid.main .container.box .row .form-control {
  border-radius: 10px;
}

.vermelho {
  color: #ff0000;
}
span.nNegrito {
  font-weight: 500;
}
div.container-fluid.main .container.box .row label .cPostal {
  color: #ff0000;
  font-size: 8px;
  display: inline-block;
}
div.container-fluid.main .container.box .row label .dtEmpresa {
  color: #ff0000;
  font-size: 10px;
}
div.div_promocional {
  padding: 0 20;
  font-family: arial;
  font-size: 13px;
}
.borda {
  border: 1px solid red;
}

div.container-fluid.main .container.box .row .btn-outline-voltar:hover {
  /*background-color:#ccc;*/
  border: 1px solid #034a97;
  /*color:#FFF;*/
  color: #034a97;
}
div.container-fluid.main .container.box .row .btn-outline-voltar {
  border: 1px solid #337ab7;
  color: #337ab7;
}
.div_rodape {
  padding: 7px 0px 7px 0px;
  background: #f48024;
  text-align: center;
  position: absolute;
  bottom: 0px;
  color: #fff;
  bottom: 0;
  width: 100%;
}
@media (max-width: 768px) {
  .div_rodape {
    position: relative !important;
  }

  div.container-fluid.main .container.tit-img h1 {
    color: #ffffff;
    text-align: center;
    width: 100%;
  }
  div.container-fluid.main .container.tit-img {
    margin-top: 0%;
    padding-top: 15%;
    background-color: #193366;
  }
  div.container-fluid.main .container.box .row label .cPostal br {
    display: none;
  }

  div.container-fluid.main .container.box h2 {
    text-align: center;
  }
  div.container-fluid.main .container.box .row .btn.form-control {
    margin-top: 5%;
  }

  .pos {
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("@/assets/headerimbutido-small.png");
    padding-top: calc(100% / (1254 / 185));
    background-image: 100% 100%;
  }

  .prof {
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("@/assets/headerembutido-prof-small.png");
    padding-top: calc(100% / (1009 / 169));
    background-image: 100% 100%;
  }
}

@media (max-height: 1500px) {
  .div_rodape {
    position: relative !important;
  }
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.campoInvalido {
  border: 1px solid red !important;
}

.form-control.btn-primary[disabled],
.form-control.btn-primary[disabled]:hover {
  background-color: #777;
  border: 1px solid #666;
}

#pais_org,
#nacionalidade {
  text-transform: uppercase;
}
</style>
