export default [
    {
        left: {
            disabled: true,
            text: "Voltar"
        },
        right: {
            disabled: false,
            text: "Continuar"
        },
        component: "Pessoa"
    },
    {
        left: {
            disabled: false,
            text: "Voltar"
        },
        right: {
            disabled: false,
            text: "Continuar"
        },
        component: "Curso"
    },
    {
        left: {
            disabled: false,
            text: "Voltar"
        },
        right: {
            disabled: false,
            text: "Continuar"
        },
        component: "PessoaComplemento"
    },
    {
        left: {
            disabled: false,
            text: "Voltar"
        },
        right: {
            disabled: false,
            text: "Enviar Dados"
        },
        component: "Confere"
    },
    {
        left: {
            disabled: true,
            text: "Voltar"
        },
        right: {
            disabled: false,
            text: "Início"
        },
        component: "Confirma"
    }
]