import { required, helpers, minLength } from "@vuelidate/validators"
import { validateDate } from "./validateDate";

export function validarCep(cep) {
  return new RegExp(/^\d{5}\-\d{3}$/).test(cep)
}


export function PessoaComplementoValidation() {
  var object = {};
  object = {}

  object["sexo"] = {
    required: helpers.withMessage("Selecione o sexo", required),
  }
  object["racaCor"] = {
    required: helpers.withMessage("Selecione Raça/Cor", required),
  }
  object["datnasc"] = {
    date_validation: {
      required: helpers.withMessage("Preencha Data de Nascimento", required),
      $validator: validateDate, $message: "Data de Nascimento Inválida."
    }
  }
  object["estadoCivil"] = {
    required: helpers.withMessage("Selecione o Estado Civil", required),
  }
  object["nacionalidade"] = {
    required: helpers.withMessage("Selecione a Nacionalidade", required),
  }
  object["pais_orig"] = {
    required: helpers.withMessage("Selecione o País de Origem", required),
  }
  object["estadoOrigemBrasil"] = {
    required: helpers.withMessage("Selecione o Estado", required),
  }
  object["estadoOrigemExterior"] = {
    required: helpers.withMessage("Preencha o Estado", required),
  }
  object["cidadeOrigemBrasilCodigo"] = {
    required: helpers.withMessage("Selecione a Cidade", required),
  }
  object["cidadeOrigemExterior"] = {
    required: helpers.withMessage("Preencha a Cidade", required),
  }
  object["cep"] = {
    cep_validation: {
      $validator: validarCep, 
      $message: "CEP Inválido",
    },
  }
  object["endereco"] = {
    required: helpers.withMessage("Endereço  é requerido", required),
  }
  object["numero"] = {
    required: helpers.withMessage("Número é requerido", required),
  }
  object["bairro"] = {
    required: helpers.withMessage("Bairro é requerido", required),
  }
  object["cidade_end"] = {
    required: helpers.withMessage("Cidade é requerido", required),
  }
  object["estado_end"] = {
    required: helpers.withMessage("Estado é requerido", required),
  }
  object["ficouSabendo"] = {
    required: helpers.withMessage("Como Ficou Sabendo é requerido", required),
  }

  return object;
}