export default {
    PRODUCAO: {
      POS: "https://inscricaopos.umc.br",
      EAD: "https://inscricaoextensao.umc.br",
      SERVER_API: "https://infra099.umc.br",
      PARAM:"eadclass"
    },
    DESENVOLVIMENTO: {
      POS: "https://inscricaoposdesenv.umc.br",
      EAD: "https://inscricaoextensaodesenv.umc.br",
      SERVER_API: "https://infra071.umc.br",
      PARAM: "eadclass"
    },
    LOCAL: {
      POS: "http://10.10.1.9",
      EAD: "http://localhost",
      SERVER_API: "http://10.10.1.9:8080",
      PARAM: "eadclass"
    }
}