<template>
  <div class="error-message">
  <span  v-for="(error, index) of errors" :key="index"><span v-text="error.$message"></span></span>
</div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.error-message {
  color: red;
  font-size: smaller;
  text-align: left;
}
</style>