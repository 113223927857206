<template>
    <div class="container">
        <div class="pad"><p>Prezado(a) <strong v-text="pessoa.nome" class="text-center"></strong>,</p>  
            <p>Recebemos com sucesso a sua matrícula.</p>
            <p v-if="naoGratuitoEPresencial()">Para concluí-la, siga as orientações abaixo:</p>
                
            <strong v-if="temBoletoParaDownload()">Clique em Emitir Boleto no botão abaixo e realize o pagamento.<br><br></strong>
            
            <div v-if="cursoExtensaoGratuitoEAD()">
              <strong>
                <p>O acesso ao ambiente virtual de aprendizagem (AVA) deverá ocorrer 24 horas antes do início do curso.</p>
                <p>Esse curso será acessado através do link Portal do Aluno <a href="http://aluno.umc.br/">http://aluno.umc.br/</a>, onde deverá ser feito seu primeiro acesso para cadastrar uma senha, clicando no link: "Esqueceu sua senha / Primeiro acesso."</p>
                <p>Os dados para login no Portal do Aluno serão encaminhados para o seu e-mail.</p>
              </strong>          
            </div>

            <div v-if="cursoExtensaoPago()">
              <h6>Comparecer na Secretaria da Pós-Graduação da UMC munidos dos seguintes documentos:</h6>
              <li>Cópia do RG e do CPF</li>
              <li>No ato da entrega dos documentos o candidato deverá assinar o requerimento de matrícula;</li>
              <br>
              <span><strong>Estamos à disposição, qualquer dúvida, entre em contato pelos telefones <span style="color:blue">4798-7080</span> ou <span style="color:blue">4798-7068</span> ou pelo e-mail <a href="posgraduacao@umc.br">posgraduacao@umc.br</a>.</strong></span>
            </div>

            <div v-if="cursoLatoSensu()"> 
              <h6><strong>Comparecer na Secretaria da Pós-Graduação da UMC munidos dos seguintes documentos:</strong></h6>
              <li>Cópia do RG e do CPF</li>
              <li>Cópia do diploma ou cópia do Certificado de Conclusão do ensino superior <span style="color:blue"><strong>*</strong></span></li>
              <span style="color:blue"><strong>* Os candidatos que não apresentarem o diploma registrado de curso superior na data da matrícula, deverão entregá-lo no prazo máximo de 180 dias após a matrícula, mediante assinatura do Termo de Compromisso sob pena de cancelamento do contrato.</strong></span><br><br>
            
              <h6><strong>Se você é ex-aluno concluinte da UMC, siga as orientações:</strong></h6>
              <li>Os ex-alunos concluintes da UMC estão isentos do pagamento do boleto de matrícula;</li>
              <li>No ato da entrega dos documentos (item 1), o candidato deverá assinar o contrato e o requerimento de matrícula;</li>
              <li>Após a efetivação da matrícula, será concedida bolsa de incentivo à educação nas demais parcelas, conforme divulgado.</li>
              <br>
            
              <h6><strong>Se você é beneficiário do Convênio Corporativo:</strong></h6>
              <li>Aos beneficiários do Convênio Corporativo vinculados as empresas ou instituições conveniadas serão concedidas bolsas com redução na matrícula, conforme divulgado;</li>
              <li>No ato da entrega dos documentos (item 1) e dos documentos comprobatórios de vínculo com a empresa, o candidato deverá assinar o contrato e o requerimento de matrícula;</li>
              <li>Após a efetivação da matrícula, será concedida bolsa de incentivo à educação nas demais parcelas, conforme divulgado.</li>
              <br>
              <p><strong> Estamos à disposição, qualquer dúvida, entre em contato pelos telefones <span style="color:blue">4798-7080</span> ou <span style="color:blue">4798-7068</span> ou pelo e-mail <a href="posgraduacao@umc.br">posgraduacao@umc.br</a>.</strong></p>

            </div>
            <p><br><button class="btn btn-success" @click="downloadBoleto()" v-if="temBoletoParaDownload()">Download Boleto (pdf)</button></p>
        </div>
    </div>    
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import qs from "qs";

export default {
  name: "Confirma",
  expose: ["fieldsAreValid", "clearFields"],
  computed: {
    ...mapGetters({
      curso: "getCurso",
      detalhesDoCurso: "getDetalhesDoCurso",
      categoria: "categoria",
      pessoa: "getPessoa",
      respostaInscricao: "getRespostaInscricao",
      contrato: "contrato",
      server: "server",

    }),
  },
  methods: {
    ...mapMutations({
      showSpinner: "setShowSpinner",
    }),
    clearFields(){

    },
    fieldsAreValid() {
      console.log("recarrega", "fieldsAreValid()")
      window.location.reload();
    },
    downloadBoleto() {
    this.showSpinner(true)
      axios
        .post(
          `${this.server}/POSGRAD/boleto/registra`,
          qs.stringify({
            "titulo.numeroTitulo": `${this.respostaInscricao.titulo}`,
          }),
          {
            headers: {
              Accept: "*/*",
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        )
        .then((response) => {
          this.showSpinner(false);
          let message = response.data;


          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = message['url'];
          a.target = "_blank"
          a.download = "boleto";
          a.click();
          document.body.removeChild(a);

        })
        .catch((error) => {
          console.log("error: ", error)
          console.log("error.response: ", error.response)

          let response = error.response

          if(response){
            let message = response.data;  
            if (message["msg"]) {
              this.showSpinner(false);
              this.$toast.error(message["msg"], { position: "top" });
            } else {
              this.showSpinner(false);
              this.$toast.error(JSON.stringify(message), { position: "top" });
            }
            
          }
        })
        .finally(() => {
          this.showSpinner(false);
        });
    },
    temBoletoParaDownload() {
      if (this.categoria.nivel['1']){
        return this.curso.ex_aluno === 'SEM_VINCULO';
      }
      if(this.categoria.nivel['EXT']) {
        return !this.categoria.gratuito
      }
      if(this.categoria.nivel['3']) {
        return !this.categoria.gratuito
      }
    },
    cursoExtensaoGratuitoEAD() {
      return this.categoria.nivel["EXT"] && this.categoria.gratuito && this.categoria.reg_id == "EAD";
    },
    cursoExtensaoPago() {
      return (this.categoria.nivel["EXT"] && !this.categoria.gratuito) || 
       (this.categoria.nivel["3"] && !this.categoria.gratuito)  ;
    },
    cursoLatoSensu(){
      return this.categoria.nivel["1"];
    },
    naoGratuitoEPresencial(){
      return !(this.categoria.reg_id == "P" && this.categoria.gratuito);
    }
  },
};
</script>
