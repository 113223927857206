import niveis_ from "@/common/niveis.js"
import ufs_ from '@/common/ufs.js'

export const ModuloPessoaComplemento = {
  state: () => ({
    niveis: niveis_,
    ufs: ufs_,
    compl: {
      nome_social: "",
      sexo: "",
      racaCor: "",
      datnasc: "",
      estadoCivil: "",
      profissao: "",
      nacionalidade: "",
      pais_orig: "",
      cep_cidade_nasc: "",
      ufNascimento: "",
      municNasci: "",
      caixaPostal: "",

      cep: "",
      endereco: "",
      numero: "",
      endcompl: "",
      bairro: "",
      cidade_end: "",
      estado_end: "",

      empresa: "",
      cep_empresa: "",
      endereco_empresa: "",
      numero_empresa: "",
      endcompl_empresa: "",
      bairro_empresa: "",
      cidade_empresa: "",
      ufEmpresa: "",

      ddd_empresa: "",
      telefone_empresa: "",
      ramal_empresa: "",

      cidadeNasc: "",
      nomCidadeNasc: "",

      ficouSabendo: ""
    },
  }),
  mutations: {
    setCompl(state, compl) {
      state.compl = compl
    },
  },
  getters: {
    getCompl(state) {
      return state.compl
    },
    getNiveis(state) {
      return state.niveis
    },
    ufs(state) {
      return state.ufs
    },
  }
}
