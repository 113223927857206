const DIA = 0
const MES = 1
const ANO = 2

export function validateDate(data) {
  if (!data) {
    return false
  }
  var dtArray = data.split("/");

  if (!dtArray) {
    return false;
  }

  var _Dia = dtArray[DIA];
  var _Mes = dtArray[MES];
  var _Ano = dtArray[ANO];

  if (!_Dia) {
    return false
  }
  if (!_Mes) {
    return false
  }
  if (!_Ano) {
    return false
  }
  if (_Ano < 1900) {
    return false
  }


  if (_Mes < 1 || _Mes > 12) {
    return false;
  }
  else if (_Dia < 1 || _Dia > 31) {
    return false;
  }
  else if ((_Mes == 4 || _Mes == 6 || _Mes == 9 || _Mes == 11) && _Dia == 31) {
    return false;
  }
  else if (_Mes == 2) {
    var isleap = (_Ano % 4 == 0 && (_Ano % 100 != 0 || _Ano % 400 == 0));
    if (_Dia > 29 || (_Dia == 29 && !isleap)) {
      return false;
    }
  }
  return true;
}
