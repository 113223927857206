import { createApp } from 'vue'
import App from '@/App.vue'
//import index from '@/router/index'
import pos from '@/router/index'
import eadclass from '@/router/eadclass'
import preclass from '@/router/preclass'
import extpre from '@/router/extpre'
import ead from '@/router/ead'
import store from '@/store'
import VueTheMask from 'vue-the-mask'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Toaster from "@meforma/vue-toaster";
import LOCATIONS from "@/common/locations.js"
import axios from 'axios';

let keys = Object.keys(LOCATIONS)
const ANO = 0;
const SEMESTRE = 1;
let router
let categoria
let serverApi

const protocol = window.location.protocol + "//"
const hostname = protocol + window.location.hostname

for (let i = 0; i < keys.length; i++) {
  let key = keys[i]
  let location = LOCATIONS[key]

  serverApi = location.SERVER_API

  if (hostname === location.EAD) {
    //const param = window.location.href.endsWith(location.PARAM)
    let param = window.location.href.split("/");
    param = param[param.length-1];

    router = ead
    categoria = "EAD"
    if (param === "eadclass") {
      router = eadclass
      categoria = "EADC"
    } else if (param === "preclass") {
      router = preclass
      categoria = "PRECLASS"
    } else if (param === "extpre") {
      router = extpre
      categoria = "EXTPRE"
    }
    break
  } else if (hostname === location.POS) {
    router = pos
    categoria = "POS"
    break
  }
}

axios.get(serverApi + "/POSGRAD/pos/inscricao/periodos")
.then(response => {
  if (response.status == 200) {
    let periodo = response.data[0];
    if(periodo){

      if(periodo.length != 2){
        throw new Error(`Ano ou Semestre não retornado do endpoint. Objeto retornado <<${periodo}>>`);        
      }
      
      if(!semestreValid(periodo[SEMESTRE])){
        throw new Error(`Semestre inválido <<${periodo[SEMESTRE]}>>` );        
      }

      if(!anoValid(periodo[ANO])){
        throw new Error(`Ano inválido <<${periodo[ANO]}>>` );        
      }

      startMain({
        ano: periodo[ANO],
        semestre: periodo[SEMESTRE]
      });

    } else{
      throw new Error("Período em branco");        
    }
  }
}).catch(error => {
  throw new Error("Problemas na inicialização - 'main.js' 'Vue' >> " + error.message);  
})

function startMain(periodo){
  store.commit("setPeriodo", periodo);
  store.commit("setCurrentCategoria", categoria)
  store.commit("setCurrentServer", serverApi)
  var app = createApp(App);
  app.use(store).use(Toaster, { position: "top", }).use(router).use(VueTheMask).mount('#app');

  app.directive('autofocus', {
                                mounted(el) {
                                  el.focus()
                                }
                              }
  )

  var properties = app.config.globalProperties;

  properties.dataToRequest = function (url_, function_) {
    return {
      url: `${store.state.common.server}${url_}`,
      doAfter(response) {
        function_(response)
      }
    }
  }
}


export function semestreValid(semestre) {
  return new RegExp(/^1|2$/).test(semestre)
}
export function anoValid(ano) {
  return new RegExp(/^\d{4}$/).test(ano)
}
