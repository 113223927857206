<template>
  <div class="container">
    <div class="row">
      <span class="required col-md-12">(*) Campos obrigat&oacute;rios</span>
    </div>
    <div class="container pessoa shadow-lg p-3 mb-3 rounded border border-secondary">
      
      <div class="row">
        <div class="form-group col-md-12">
          <label for="nome">Nome Completo<span class="required">*</span><ErrorMsg :errors="v$.pessoa.nome.$errors"/></label>
          <input name="nome" id="nome" type="text" class="form-control" maxlength="80" v-model="pessoa.nome" v-autofocus ref="nome"/>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-4">
          <label for="cpf">CPF<span class="required">*</span><ErrorMsg :errors="v$.pessoa.cpf.$errors"/></label>
          <input name="cpf" id="cpf" type="tel" class="form-control" max="14" masked="true" v-mask="'###.###.###-##'" placeholder="___.___.___-__" v-model="pessoa.cpf" ref="cpf"/>
        </div>

        <div class="form-group col-md-4">
          <label for="rg">RG<span class="required">*</span><ErrorMsg :errors="v$.pessoa.rg.$errors"/></label>
          <input name="rg" id="rg" type="tel" class="form-control" maxlength="12" v-model="pessoa.rg" ref="rg"/>
        </div>

        <div class="form-group col-md-4">
          <label for="origemrg">Origem do RG<span class="required">*</span><ErrorMsg :errors="v$.pessoa.origemrg.$errors"/></label>
          <select class="form-control form-select" name="origemrg" id="origemrg" v-model="pessoa.origemrg" ref="origemrg">
            <option value="" selected>Selecione</option>
            <option v-for="( name, key) in ufs" v-text="name" :value="key" :key="key"></option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="email">E-mail<span class="required">*</span><ErrorMsg :errors="v$.pessoa.email.$errors"/></label>
          <input name="email" id="email" type="email" class="form-control" maxlength="255" v-model="pessoa.email" ref="email"/>
        </div>
      
        <div class="form-group col-md-1">
          <label for="ddd_cel">DDD<span class="required">*</span><ErrorMsg :errors="v$.pessoa.ddd_cel.$errors"/></label>    
          <input name="ddd_cel" id="ddd_cel" type="tel" class="form-control" masked="true" v-mask="'##'" placeholder="__" v-model="pessoa.ddd_cel" ref="ddd_cel"/>
          
        </div>

        <div class="form-group col-md-2">
          <label for="celular">Celular<span class="required">*</span><ErrorMsg :errors="v$.pessoa.celular.$errors"/></label>    
          <input name="celular" id="celular" type="tel" class="form-control col" masked="true" v-mask="'#####-####'" placeholder="_____-____"  v-model="pessoa.celular" ref="celular"/>
        </div>

        <div class="form-group col-md-1">
          <label for="ddd">DDD</label>
          <input name="ddd" id="ddd" type="tel" class="form-control" maxlength="2" v-mask="'##'" placeholder="__"  v-model="pessoa.ddd"/>
        </div>

        <div class="form-group col-md-2">
          <label for="telefone">Telefone Fixo</label>
          <input name="telefone" id="telefone" type="tel" class="form-control" maxlength="10" v-mask="'####-####'" placeholder="____-____" v-model="pessoa.telefone"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import ErrorMsg from "@/components/ErrorMsg.vue";
import { PessoaValidation } from "@/common/validation/PessoaValidation.js";

export default {
  name: "Pessoa",
  components: {
    ErrorMsg,
  },
  computed: {
    ...mapGetters({
      pessoa: "getPessoa",
      categoria: "categoria",
      ufs:"ufs"
    }),
    titulo() {
      return "titulo";
    },
  },
  validations() {
    return {
      ...PessoaValidation()
    };
  },
  methods: {
    ...mapActions({doRequest:"doRequest"}),
    ...mapMutations({setPessoa:"setPessoa"}),
    fieldsAreValid(toNextPosition) {
      this.v$.$validate()
      let hasError = this.v$.$error
      if(hasError){
        let error = this.v$.$errors[0] 
        let message 
        if(error.$message === "Invál."){
          message = `${error.$property} ${error.$message}` 
        }else{
          message = error.$message
        }
        this.$refs[error.$property].focus()
        this.$toast.error(message, {position: "top",});
      }else{
        var url = this.categoria.preInscricaoURL(this.pessoa);
        var parametrosRequisicao = this.dataToRequest(url, this.extractId);
        this.doRequest(parametrosRequisicao);
        this.setPessoa(this.pessoa);
        toNextPosition();
      }
    },
    extractId(json) {
      this.pessoa.id = json.id;
    },
  },
  data() {
    return {
      v$: useValidate(),
      name: "Pessoa",
    };
  },
  expose: ["fieldsAreValid"],
};
</script>

<style scoped>
.ddd{
  padding-right: 0.1rem;
}
.fone{
  padding-left:  0.1rem;
}
.required {
  color: red;
  margin-left: 2px;
}
.ddd {
  margin-right: 0;
}
label {
  display: block;
  text-align: left;
}
.form-group {
  margin-bottom: 0.5rem;
}
</style>
