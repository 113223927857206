export default {
    $length: 0,
    $current: 0,
    $next: 1,
    $previous: 1,

    setLength: function (length) {
        this.$length = length
    },

    next: function () {
        this.$next = (this.$current + 1) % this.$length;
        this.$current = this.$next;
    },

    previous: function () {
        this.$previous = (this.$current + this.$length - 1) % this.$length
        this.$current = this.$previous
    },

    getNext: function () {
        return this.$next
    },

    getPrevious: function () {
        return this.$previous
    },

    getCurrent: function () {
        return this.$current
    }
}