<template>
  <div class="container p-1">
    
    <div class="container shadow-lg p-3 mb-3 rounded border border-secondary">
      <div class="row">
        <label class="col-sm-6">Nome Completo: <span v-text="pessoa.nome"></span></label>
        <label class="col-sm-6">Vínculo com a UMC: <span v-text="curso.ex_aluno"></span></label>
      </div>
      <div class="row">
        <label class="col-sm-3">CPF: <span v-text="pessoa.cpf"> </span></label>
        <label class="col-sm-3">RG: <span v-text="pessoa.rg"></span></label>
        <label class="col-sm-3">Origem RG: <span v-text="ufs[pessoa.origemrg]"></span></label>
        <label class="col-sm-3">E-mail: <span v-text="pessoa.email"></span></label>
      </div>
      <div class="row">
        <label class="col-sm-3">E-mail alternativo: <span v-text="compl.email_alt"></span></label>
        <label class="col-sm-3">Celular: <span v-text="pessoa.ddd_cel"></span>-<span v-text="pessoa.celular"></span></label>
        <label class="col-sm-3">Telefone Fixo: <span v-text="pessoa.ddd"></span>-<span v-text="pessoa.telefone"></span></label>
        <label v-if="curso.rgm" class="col-sm-3">RGM: <span v-text="curso.rgm"></span></label>
      </div>
    </div>

    <div class="container shadow-lg p-3 mb-3 rounded border border-secondary">
      <div class="row">
          <label class="col-sm-2">Nível: <span v-text="categoria.nivel[curso.nivelValor]"></span></label>
          <label class="col-sm-4">Campus: <span v-text="curso.nome_campus"></span></label>
          <label class="col-sm-4">Curso: <span v-text="curso.nome_curso"></span></label>
          <label class="col-sm-2">Turma: <span v-text="curso.turma.substring(0,3)"></span></label>
      </div>
      <div class="row">
          <label class="col-sm-3">Horário: <span v-text="detalhesDoCurso.horario"></span></label>
          <label class="col-sm-6">Dias da Semana: <span v-text="detalhesDoCurso.diasSemana"></span></label>
          <label class="col-sm-3">Período: <span v-text="detalhesDoCurso.periodo"></span></label>
      </div>
      <div class="row" v-if="!categoria.gratuito">
          <label class="col-sm-6">Valor total: <span v-text="detalhesDoCurso.valorTotal"></span></label>
          <label class="col-sm-6">Núnero de parcelas: <span v-text="detalhesDoCurso.numerosParcelas"></span></label>
      </div>
      <div class="row" v-if="!categoria.gratuito">
          <label class="col-sm-4">Valor da 1ª parcela (matrícula): <span v-text="format(detalhesDoCurso.valorPrimeiraParcela)"></span></label>
          <label class="col-sm-3">Valor promocional: <span v-text="format(detalhesDoCurso.valorParcelaDesc)"></span></label>
          <label class="col-sm-5">Valor sem desconto: <span v-text="format(detalhesDoCurso.valorParcela)"></span></label>
      </div>
    </div>

    <div class="container shadow-lg p-3 mb-3 rounded border border-secondary">
      <div class="row">
          <label class="col-sm-3">Nome Social: <span v-text="compl.nome_social"></span></label>
          <label class="col-sm-3">Data de Nascimento: <span v-text="compl.datnasc"></span></label>
          <label class="col-sm-3">Estado Civíl: <span v-text="estadosCivis[compl.estadoCivil]"></span></label>
          <label class="col-sm-3">Profissão: <span v-text="compl.profissao"></span></label>
      </div>
      <div class="row">
          <label class="col-sm-3">Sexo: <span v-text="sexos[compl.sexo]"></span></label>
          <label class="col-sm-3">Cor / RaÇa: <span v-text="compl.racaCor"></span></label>
          <label class="col-sm-3">Nacionalidade: <span v-text="nacionalidades[compl.nacionalidade]"></span></label>
          <label class="col-sm-3">País de Origem: <span v-text="paises[compl.pais_orig]"></span></label>
      </div>
      <div class="row">
          <label class="col-sm-3">UF de nascimento: <span v-text="compl.ufNascimento"></span></label>
          <label class="col-sm-6">Município de nascimento: <span v-text="compl.municNasci"></span></label>
          <label class="col-sm-3">CEP: <span v-text="compl.cep"></span> </label>
      </div>
      <div class="row">
          <label class="col-sm-6">Endereço: <span v-text="compl.endereco"></span></label>
          <label class="col-sm-3">Caixa Postal: <span v-text="compl.caixaPostal"></span></label>
          <label class="col-sm-3">Número: <span v-text="compl.numero"></span></label>
      </div>
      <div class="row">
          <label class="col-sm-3">Complemento: <span v-text="compl.endcompl"></span></label>
          <label class="col-sm-3">Bairro: <span v-text="compl.bairro"></span></label>
          <label class="col-sm-3">Cidade: <span v-text="compl.cidade_end"></span></label>
          <label class="col-sm-3">Estado: <span v-text="compl.estado_end"></span></label>
      </div>
      <div class="row">
          <label class="col-sm-6">Empresa: <span v-text="compl.empresa"></span></label>
          <label class="col-sm-6">CEP: <span v-text="compl.cep_empresa"></span></label>
      </div>
      <div class="row">
          <label class="col-sm-6">Endereço: <span v-text="compl.endereco_empresa"></span></label>
          <label class="col-sm-3">Número: <span v-text="compl.numero_empresa"></span></label>
          <label class="col-sm-3">Complemento: <span v-text="compl.endcompl_empresa"></span></label>
      </div>
      <div class="row">
          <label class="col-sm-3">Bairro: <span v-text="compl.bairro_empresa"></span></label>
          <label class="col-sm-3">Cidade: <span v-text="compl.cidade_empresa"></span></label>
          <label class="col-sm-3">Telefone: <span v-text="compl.telefone_empresa"></span></label>
          <label class="col-sm-3">Ramal: <span v-text="compl.ramal_empresa"></span></label>
      </div>
      <div class="row">
          <label class="col-sm-6">Como ficou sabendo da UMC: <span v-text="origensAluno[compl.ficouSabendo]"></span></label>
      </div>
    </div>
</div>  
</template>

<script>
import formatNumber from '@/common/formatNumber.js'
import { mapGetters, mapMutations } from "vuex";
import vinculos_ from "@/common/vinculos.js";
import sexos_ from "@/common/sexos.js"
import qs from 'qs';
import axios from 'axios'

export default {
  name: "Confere",
  expose: ["fieldsAreValid", "clearFields"],
  data(){
    return {
      isFieldsAreValid:false,
      vinculos : vinculos_,
      sexos: sexos_,
    }
  },
  watch:{
/*    respostaInscricao(novo){
      console.log("watch: ",novo)
    }*/
  },
  methods: {
    ...mapMutations({
      setRespostaInscricao:"setRespostaInscricao",
      showSpinner: "setShowSpinner"
    }),

    format(text){
      return formatNumber.format.format(text)
    },
    clearFields(){
      console.log(this.categoria.gratuito)
      
    },
    fieldsAreValid(toNextPosition) {
      var payload = {
        anoconclusao            : "",
        orgaoexprg              : "",
        nome_curso_ead          : "",
        habilitacao             : "",
        cmb_cidade_formacao     : "",
        graduacao               : "",
        cmb_instituicao         : "", 
        ramal                   : "", 
        datexprg                : "",
        
        codPrecadastro          : this.pessoa.id,
        nome                    : this.pessoa.nome,
        cpf                     : this.pessoa.cpf.replaceAll(".", "").replaceAll("-", ""),
        rg                      : this.pessoa.rg.replaceAll(".", "").replaceAll("-", ""),
        origemrg                : this.pessoa.origemrg,
        ddd_cel                 : this.pessoa.ddd_cel,
        celular                 : this.pessoa.celular,
        ddd                     : this.pessoa.ddd,
        telefone                : this.pessoa.telefone,
        email                   : this.pessoa.email,
        rgm                     : this.curso.rgm,
        tipoinscr               : this.curso.ex_aluno,
        
        nome_social             : this.compl.nome_social,
        sexo                    : this.compl.sexo,
        racaCor                 : this.compl.racaCor, 
        datnasc                 : this.compl.datnasc.replaceAll("/", ""),
        estadoCivil             : this.compl.estadoCivil,
        profissao               : this.compl.profissao,
        nacionalidade           : this.compl.nacionalidade.trim(),
        pais_orig               : this.compl.pais_orig.trim(),
        ufNascimento            : this.compl.ufNascimento,
        cep_cidade_nasc         : this.compl.cep_cidade_nasc ? this.compl.cep_cidade_nasc.trim() : "",
        municNasci              : this.compl.municNasci ? this.compl.municNasci.trim() : "",
        caixaPostal             : this.compl.caixaPostal,
        
        cep                     : this.compl.cep.replace("-", ""),
        endereco                : this.compl.endereco,
        numero                  : this.compl.numero,
        endcompl                : this.compl.endcompl,
        bairro                  : this.compl.bairro,
        cidade                  : this.compl.cidade_end,
        estado_end              : this.estado_end,
        
        empresa                 : this.compl.empresa,
        cep_empresa             : this.compl.cep_empresa.replace("-", ""),
        endereco_empresa        : this.compl.endereco_empresa,
        numero_empresa          : this.compl.numero_empresa,
        endcompl_empresa        : this.compl.endcompl_empresa,
        bairro_empresa          : this.compl.bairro_empresa,

        ddd_empresa             : this.compl.ddd_empresa,
        telefone_empresa        : this.compl.telefone_empresa,
        ramal_empresa           : this.compl.ramal_empresa ,

        cmb_campus              : this.curso.cmb_campus,
        cmb_turma_ead           : this.curso.turma,
        cmb_nivel               : this.niveis[this.curso.nivelValor],
        polo                    : "",
        
        regime                  : this.categoria.reg_id,
        nome_curso              : this.curso.nome_curso
      }

      if(this.categoria.reg_id ==="EAD"){
        payload.turma          = ""
        payload.nivel          = this.curso.nivelValor
        payload.curso          = "" 
        payload.nome_campus    = "EAD"
        payload.cmb_campus     =  "ED"

        payload.nome_polo      = this.campus[this.curso.campusModel]
        payload.categoria_ead  = this.curso.nivelValor
        payload.cmb_curso_ead  = this.curso.cursoModel
        payload.cmb_polo       = this.curso.campusModel.trim()
        payload.cmb_turma_ead  = this.curso.turma
      }else if(this.categoria.reg_id ==="P"){
        payload.turma          = this.curso.turma
        payload.nivel          = this.curso.nivelValor
        payload.curso          = this.curso.cursoModel
        payload.nome_campus    = this.campus[this.curso.campusModel]

        payload.nome_polo      = ""
        payload.categoria_ead  = ""
        payload.cmb_curso_ead  = "" 
        payload.cmb_polo       = ""
        payload.cmb_turma_ead  = ""
      }
      
      this.showSpinner(true);
      axios.post(`${this.server}/POSGRAD/persist`,qs.stringify(payload), {headers: {'Accept':'*/*','Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(response => {
          toNextPosition();
          this.setRespostaInscricao(response.data)
          this.showSpinner(false);
        }).catch(error => {
          let message = error.response.data
          if(message['msg']){
            this.showSpinner(false);
            this.$toast.error(message['msg'], {position: "top",});
          }else{
            this.showSpinner(false);
            this.$toast.error(JSON.stringify(message), {position: "top",});
          }
        })
        .finally(() => {
          this.showSpinner(false);
        });
  },
  },
  computed: {
    ...mapGetters({
      respostaInscricao: "getRespostaInscricao",
      pessoa: "getPessoa",
      compl: "getCompl",
      curso: "getCurso",
      detalhesDoCurso: "getDetalhesDoCurso",
      categoria: "categoria",
      campus:"getCampusPolo",
      estadosCivis:"getEstadosCivis",
      nacionalidades:"getNacionalidades",
      paises:"getPaises",
      origensAluno:"getOrigensAluno",
      niveis: "getNiveis",
      server: "server",
      ufs:"ufs",
      contrato:"contrato"
    }),
  },
  expose: ["fieldsAreValid","clearFields"],
};
</script>

<style scoped>
span{
  font-weight: bold;
  font-size: 0.97rem;
}
label{
  display: block;
  text-align: left;
}
</style>
