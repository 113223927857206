export const ModuloPessoa = {
  state: () => ({
    pessoa: {
      id: "",
      nome: "",
      cpf: "",
      rg: "",
      origemrg: "",
      ddd_cel: "",
      celular: "",
      ddd: "",
      telefone: "",
      email: "",
      email_alt: "",
      ex_aluno: "",
      rgm: "",
    },

  }),
  mutations: {
    setPessoa(state, pessoa) {
      state.pessoa = pessoa;
    },
    cpf(state) {
      return state.pessoa.cpf
    },

  },
  getters: {
    getPessoa(state) {
      return state.pessoa
    },

  }
}
