import { required, helpers, minLength } from "@vuelidate/validators";
import {validateCPF} from './ValidateCPF';

export function validarCpf(cpf) {
  return validateCPF().isValid(cpf)
}

export function validarEmail(email) {
  return new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(email)
}

//export function validarRG(rg) {
//  return new RegExp(/\d{2}\.\d{3}\.\d{3}-[0-9]/).test(rg)
//}

export function validarDDDCelular(ddd) {
  return new RegExp(/^\d{2}$/).test(ddd)
}

export function validarCelular(celular) {
  return new RegExp(/^\d{5}\-\d{4}$/).test(celular)
}

export function PessoaValidation() {
  var object = {};
  object["pessoa"] = {}
  object.pessoa["nome"] = {
    required: helpers.withMessage("Nome é requerido.", required),
    minLength: helpers.withMessage("Nome muito pequeno.", minLength(4)),
  }
  object.pessoa["cpf"] = {
    name_validation: {
      $validator: validarCpf, 
      $message: "CPF Inválido",
    },
  }
  object.pessoa["rg"] = {
    required: helpers.withMessage("RG não pode ficar em branco", required),
  }
  object.pessoa["origemrg"] = {
    required: helpers.withMessage("Selecione a Origem do RG.", required),
  }
  object.pessoa["email"] = {
    email_validation: {
      $validator: validarEmail,
      $message: "E-mail Inválido",
    },
  }
  object.pessoa["ddd_cel"] = {
    dddCelular_validation: {
      $validator: validarDDDCelular,
      $message: "Inválido"
    }
  }
  object.pessoa["celular"] = {
    celular_validation: {
      $validator: validarCelular,
      $message: 'Inválido'
    }
  }

  return object;
}