import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Error from '@/views/Error.vue'

const routes = [
  {
    path: '/:catchAll(.*)',
    name: 'Error',
    component: Error,
  },
  {
    path: '/preclass',
    name: 'preclass',
    component: HomeView
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});


export default router;
