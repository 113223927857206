import categorias_ from '@/common/categoria/categoria.js'

export const ModuloCommon = {
    state: () => ({
        categorias: categorias_,
        currentCategoria: {},
        server: "",
        periodo:{}
    }),
    mutations: {
        setPeriodo(state, periodo){
            state.periodo = periodo;
        },
        setCurrentCategoria(state, currentCategoria) {
            state.currentCategoria = currentCategoria
        },
        setCurrentServer(state, server) {
            state.server = server
        },
    },
    actions: {},
    getters: {
        categoria(state) {
            let categ = state.categorias[state.currentCategoria];
            categ.semestre = state.periodo.semestre;
            categ.ano = state.periodo.ano;
            return categ;
        },
        server(state) {
            return state.server
        }
    }
}