export default {
  reg_id: "EAD",
  semestre: "",
  ano: "",
  flag: "prof",
  description: "Extensão Class",
  gratuito:false,
  nivel: { EXT: "Extensão" },
  label: "Polo",
  cursosUrl(campus, nivel) {
    return `/POSGRAD/pos/inscricao/especializacao/pago/ED/${nivel}/${campus}`
  },
  turmaUrl(campus, curso) {
    return `/POSGRAD/pos/inscricao/turma/paga/ED/${curso}`
  },
  /*detalhesCursoUrl(campus, cursoCodigo, turma, vinculo) {
    return `/POSGRAD/pos/inscricao/detalhesCurso/ED/${cursoCodigo}/${turma}/$${vinculo}`
  },*/
  vinculosDisponiveisUrl(cpf) {
    return `/POSGRAD/pos/inscricao/vinculo/disponiveis/${cpf}`
  },
  preInscricaoURL(pessoa) {
    return `/POSGRAD/pos/inscricao/preinscricao/${pessoa.cpf.replaceAll(".", "").replace("-", "")}/${pessoa.ddd ? pessoa.ddd : "0"}/${pessoa.ddd_cel}/${pessoa.email}/${pessoa.email_alt ? pessoa.email_alt : 'm'}/${pessoa.ex_aluno ? pessoa.ex_aluno : '0'}/${pessoa.nome}/${pessoa.rg.replaceAll(".", "").replace("-", "")}/${pessoa.rgm ? pessoa.rgm : '0'}/${pessoa.celular ? pessoa.celular.replace('-', '') : '0'}/${pessoa.telefone ? pessoa.telefone.replace('-', '') : '00'}`;
  },
  melhorDescontoUrl(codigoCentro, codigoCurso, codigoTurma, codigoPolo, rgm, nome, cpf) {
    return `/POSGRAD/pos/inscricao/vinculo/melhorDesconto/${codigoCentro}/${codigoCurso}/${codigoTurma}/${codigoPolo}/${rgm}/${nome}/${cpf}`
  },
  detalhesDoCursoUrl(campus, curso, turma, vinculo, campusPolo) {
    if (campusPolo === "MC") {
      campusPolo = ''
    } else {
      campusPolo = `/${campusPolo}`
    }
    return `/POSGRAD/pos/inscricao/detalhesCurso/pago/ED/${curso}/${turma}/${vinculo}${campusPolo}`
  },
  info: {
    name: "INFORMAÇÃO:",
    promocao: [],
    outras: [{
      text: `Consultem os descontos comerciais e para ex-alunos da UMC, <a href="mailto:posgraduacao@umc.br">posgraduacao@umc.br</a>`,
    }]
  },
  convenio: {
    title: "Matricula por convênio",
    text: "Ao concluir o processo da sua matrícula, entregue os documentos comprobatórios do seu vínculo na Secretaria da Pós-Graduação da UMC de sua região. O desconto promocional divulgado está condicionado na apresentação e validação dos mesmos! Caso seja aprovado, seu boleto de matrícula será atualizado com o desconto correspondente."
  }
}