export function validateCPF() {
    const PRIMEIRO_DIGITO = 9;
    const SEGUNDO_DIGITO = 10;

    function todosSaoNumeros(cpf) {
        let valid = new RegExp(/^\d{11}$/).test(cpf)

        if (!valid) {
            throw new Error("CPF tem que ter 11 números");
        }
    }

    function todosSaoRepetidos(cpf) {
        for (let index = 1; index < cpf.length; index++) {
            let indexAnterior = index - 1;
            if (cpf[indexAnterior] !== cpf[index]) {
                return false
            }
        }
        throw new Error("Digitos repetidos");
    }

    function valid(cpf, param = { maxIndex, pesoStart }) {
        let total = 0;
        let peso = param.pesoStart;
        for (let i = 0; i <= param.maxIndex; i++) {
            total += cpf[i] * peso++;
        }
        let resto = total % 11;

        var digito = resto;
        if (resto > 9) {
            digito = 0;
        }

        return digito.toString();
    }


    function segundoDigito(cpf) {
        return valid(cpf, { maxIndex: 9, pesoStart: 0 });
    }

    function primeiroDigito(cpf) {
        return valid(cpf, { maxIndex: 8, pesoStart: 1 });
    }

    function validar(cpf) {
        todosSaoNumeros(cpf);
        todosSaoRepetidos(cpf);
        let digito1 = primeiroDigito(cpf);
        let digito2 = segundoDigito(cpf);
        return (cpf[PRIMEIRO_DIGITO] === digito1) && (cpf[SEGUNDO_DIGITO] === digito2)
    }

    return {
        isValid(cpf) {
            var cpf_ = cpf.replaceAll("-", "").replaceAll(".", "");
            return validar(cpf_);
        }
    }
}