<template>
  <div class="container">
    <div class="row">
      <span class="required col-md-12">(*) Campos obrigat&oacute;rios</span>
    </div>

    <div class="container shadow-lg p-3 mb-3 rounded border border-secondary">
      <div class="row">
        <div class="form-group col-sm-4">
          <label for="nivel">Nível<span class="required">*</span><ErrorMsg :errors="v$.nivelValor.$errors"/></label>
          <select name="nivel" id="nivel" class="form-control form-select" v-model="nivelValor" v-autofocus ref="nivelValor">
            <option selected value="">Selecione</option>
            <option v-for="(name, key) in categoria.nivel" :value="key" v-text="name" :key="key"></option>
          </select>
        </div>

        <div class="col-sm-4"></div>

        <div class="form-group col-sm-4" id="campusDiv">
          <label for="campus"><span class="required">*</span><span v-text="categoria.label"></span><ErrorMsg :errors="v$.campusModel.$errors"/></label>
          <select name="campus" id="campus" class="form-control form-select" v-model.trim="campusModel" ref="campusModel">
            <option value="" selected>Selecione</option>
            <option v-for="(name, key) in campus" :value="key" :key="key" v-text="name"></option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-8">
          <label for="curso">Nome do Curso<span class="required">*</span><ErrorMsg :errors="v$.cursoModel.$errors"/></label>
          <select class="form-control form-select" id="curso" name="curso" v-model="cursoModel" ref="cursoModel">
            <option value="" selected>Selecione</option>
            <option v-for="(name, key) in cursos" :value="key" :key="key" v-text="name"></option>
          </select>
        </div>

        <div class="form-group col-sm-4">
          <label for="turma">Turma<span class="required">*</span><ErrorMsg :errors="v$.turma.$errors"/></label>
          <select class="form-control form-select" id="turma" name="turma" v-model="turma" ref="turma">
            <option value="" selected>Selecione</option>
            <option v-for="{turma, turno} in turmas" :value="turma" v-text="turno" :key="turma"></option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-6">
          <label for="ex_aluno">Vínculo com a UMC<span class="required">*</span><ErrorMsg :errors="v$.ex_aluno.$errors"/></label>
          <select class="form-control form-select" name="ex_aluno" id="ex_aluno" v-model="ex_aluno" ref="ex_aluno">
            <option value="" selected>Selecione</option>
            <option v-for="index, value, key in vinculos" :value="value" v-text="index.nomeVinculo" :key="key"></option>
          </select>
        </div>

        <div v-if="rgm" class="form-group col-sm-6">
          <label for="rgm">RGM<span class="dtEmpresa">(Somente para Aluno e Ex-Alunos)</span></label>
          <label class="form-control" v-text="rgm"></label>
        </div>
      </div>
    </div>


    <div class="row shadow-lg p-3 mb-3 rounded border border-secondary" v-if="ex_aluno === 'ALUNO_CONV'">
      <div class="jumbotron">
        <h2 v-text="categoria.convenio.title"></h2>
        <p v-text="categoria.convenio.text"></p>
      </div>
    </div>

    <div class="container pessoa shadow-lg p-3 mb-3 rounded border border-secondary" id="detalhesCurso" v-if="ex_aluno" >
      <div class="row">
        <div class="col-sm-3">
          <label>Horário</label>
          <label class="form-control" v-text="detalhesDoCurso.horario"></label>
        </div>

        <div class="col-sm-2">
          <label>Dias da Semana</label>
          <label class="form-control" v-text="detalhesDoCurso.diasSemana"></label>
        </div>

        <div class="col-sm-3">
          <label>Per&iacute;odo</label>
          <label class="form-control" v-text="detalhesDoCurso.periodo" readonly disabled></label>
        </div>

        <div class="col-sm-2">
          <label>Plano</label>
          <label class="form-control" v-text="detalhesDoCurso.plano"></label>
        </div>

        <div class="form-group col-sm-2" v-if="cursoPago()">
          <label>Número de parcelas</label>
          <label class="form-control" v-text="detalhesDoCurso.numerosParcelas"></label>
        </div>
      </div>

      <div class="row" v-if="cursoPago()">
        <div class="col-sm-5">
          <label>Valor total</label>
          <label class="form-control" v-text=" detalhesDoCurso.valorTotal"></label>
        </div>

        <div class="form-group col-sm-3">
          <label>Valor da 1ª parcela (matrícula)</label>
          <label class="form-control" v-text="format(detalhesDoCurso.valorPrimeiraParcela)"></label>
        </div>

        <div id="valor_promocional" v-if="categoria.info.promocao.length > 0" class="col-sm-2">
          <label>Valor promocional</label>
          <label class="form-control" v-text="format(detalhesDoCurso.valorParcelaDesc)"></label>
        </div>

        <div id="valor_parcelas" :class="[categoria.info.promocao.length > 0 ? 'col-sm-2' : 'col-sm-4']">
          <label>Valor sem Desconto</label>
          <label class="form-control" v-text="format(detalhesDoCurso.valorParcela)"></label>
        </div>
      </div>

      <div class="row preco">
        <div class="info div_promocional">{{categoria.info.name}}
          <ol>
            <li class="info" v-for="item, index in categoria.info.outras" v-html="item.text" :key="index"></li>
            <li class="info" v-for="item, index in categoria.info.promocao" v-html="item.text" :key="index"></li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatNumber from "@/common/formatNumber.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import useValidate from "@vuelidate/core";
import ErrorMsg from "@/components/ErrorMsg.vue";
import { CursoValidation } from "@/common/validation/CursoValidation.js";

export default {
  name: "Curso",
  components: {
    ErrorMsg,
  },
  computed: {
    ...mapGetters({
      categoria: "categoria",
      pessoa: "getPessoa",
      detalhesDoCurso: "getDetalhesDoCurso",
      curso: "getCurso",
      campusPolo: "getCampusPolo",
      cursos: "getCursos",
      server: "server",
    }),
  },
  validations() {
    return {
      ...CursoValidation(),
    };
  },
  methods: {
    ...mapActions({doRequest: "doRequest"}),
    ...mapMutations({
      setCursos: "setCursos",
      setDetalhesDoCurso: "setDetalhesDoCurso",
      setDetalhesDoCurso: "setDetalhesDoCurso",
      setCurso: "setCurso",
    }),
    format(text) {
      return formatNumber.format.format(text);
    },
    cursoPago() {
      if (this.detalhesDoCurso.gratuito) {
        return !(this.detalhesDoCurso.gratuito == "S");
      }
      return true;
    },
    populateCursos(cursos) {
      this.setCursos(cursos);
    },
    populateTurmas(turmas) {
      this.turmas = turmas;
    },
    populateVinculos(vinculos) {
      vinculos.forEach((v) => {
        if(this.categoria.gratuito){
          if( v.tipoVinculo !== 'ALUNO_CONV'){
            this.vinculos[v.tipoVinculo] = {
              nomeVinculo: v.nomeVinculo,
              rgm: v.rgm ? v.rgm : "",
            };
          }
        }else{
          this.vinculos[v.tipoVinculo] = {
          nomeVinculo: v.nomeVinculo,
          rgm: v.rgm ? v.rgm : "",
        };
        }

      });
      
    },
    populateMelhorDesconto(melhorDesconto) {
      this.melhorDesconto = melhorDesconto;
    },
    populateDetalhesDoCurso(detalhesDoCurso) {
      var d = detalhesDoCurso[0];
      this.setDetalhesDoCurso({
        horario: d.horario,
        diasSemana: d.dias,
        periodo: d.periodo,
        plano: d.plano,
        valorTotal: d.total,
        numerosParcelas: d.parcelas,
        valorPrimeiraParcela: d.primeira,
        valorParcela: d.demais,
        valorParcelaDesc: d.valorPromocional,
        gratuito: d.gratuito,
      });
    },
    fieldsAreValid(toNextPosition) {
      this.v$.$validate();
      let hasError = this.v$.$error;

      if (hasError) {
        let error = this.v$.$errors[0] 
        let message = error.$message 
        this.$refs[error.$property].focus()
        this.$toast.error(message, {position: "top",});
      } else {
        this.setCurso({
          rgm: this.rgm,
          nivelValor: this.nivelValor,
          campusModel: this.campusModel,
          cursoModel: this.cursoModel,
          turma: this.turma,
          ex_aluno: this.ex_aluno,
          nome_campus: this.campusPolo[this.campusModel],
          cmb_campus: this.campusModel,
          nome_curso: this.cursos[this.cursoModel],
        });
        toNextPosition();
      }
    },
    clearFields() {
      //this.nivelValor = ""
    },
  },
  expose: ["fieldsAreValid", "clearFields"],
  watch: {
    nivelValor(novo) {
      this.campus = {};
      this.campusModel = "";
      if (novo) {
        this.campus = this.campusPolo;
      }
    },
    campusModel(novo) {
      this.cursoModel = "";
      this.setCursos({});
      if (novo) {
        var url = this.categoria.cursosUrl(novo.trim(), this.nivelValor);
        var parametrosRequisicao = this.dataToRequest(url, this.populateCursos);
        this.doRequest(parametrosRequisicao);
      }
    },
    cursoModel(novo) {
      this.turmas = {};
      this.turma = "";
      if (novo) {
        var url = this.categoria.turmaUrl(
          this.campusModel.trim(),
          this.cursoModel
        );
        var parametrosRequisicao = this.dataToRequest(url, this.populateTurmas);
        this.doRequest(parametrosRequisicao);
      }
    },
    turma(novo) {
      this.ex_aluno = "";
      this.vinculos = {};
      this.melhorDesconto = {};
      if (novo) {
        var url = this.categoria.vinculosDisponiveisUrl(
          this.pessoa.cpf.replaceAll(".", "").replaceAll("-", "")
        );
        var parametrosRequisicao = this.dataToRequest(
          url,
          this.populateVinculos
        );
        this.doRequest(parametrosRequisicao);

        url = this.categoria.melhorDescontoUrl(
          this.cursoModel.substring(0, 1),
          this.cursoModel.substring(2, 6),
          this.turma.substring(0, 3),
          this.campusModel.trim() === "MC" ? 0 : this.campusModel.trim(),
          this.rgm ? this.rgm : "0",
          this.pessoa.nome,
          this.pessoa.cpf.replaceAll(".", "").replaceAll("-", "")
        );
        parametrosRequisicao = this.dataToRequest(
          url,
          this.populateMelhorDesconto
        );
        this.doRequest(parametrosRequisicao);
      }
    },
    ex_aluno(novo) {
      this.setDetalhesDoCurso({});
      this.detalhesDoCurso = {};
      this.rgm = "";
      if (novo) {
        var url = this.categoria.detalhesDoCursoUrl(this.campusModel.trim(), this.cursoModel, this.turma, novo, this.campusModel.trim()
        );
        var parametrosRequisicao = this.dataToRequest(
          url,
          this.populateDetalhesDoCurso
        );
        this.doRequest(parametrosRequisicao);
        if (this.vinculos[novo].rgm) {
          this.rgm = this.vinculos[novo].rgm;
        }
      }
    },
  },
  data() {
    return {
      v$: useValidate(),
      turmas: {},
      vinculos: {},
      melhorDesconto: {},
      rgm: "",
      nivelValor: "",
      campusModel: "",
      cursoModel: "",
      turma: "",
      ex_aluno: "",
      campus: {},
    };
  },
};
</script>
<style scoped>
label[class="form-control"] {
  background-color: #e9ecef;
  opacity: 1;
  font-weight: bold;
  font-size: 0.95rem;
}
.required {
  color: red;
  margin-left: 2px;
}

label {
  display: block;
  text-align: left;
}
.row {
  margin: 0.1rem;
}
.form-group {
  margin-bottom: 0.5rem;
}
</style>
