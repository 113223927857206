export const ModuloCurso = {
  state: () => ({
    cursos: {},
    curso: {
      rgm: "",
      nivelValor: "",
      campusModel: "",
      cursoModel: "",
      turma: "",
      ex_aluno: "",
    },
    detalhesDoCurso: {
      horario: "",
      diasSemana: "",
      periodo: "",
      plano: "",
      numerosParcelas: "",
      valorTotal: "",
      valorPrimeiraParcela: "",
      valorParcelaDesc: "",
      valorParcela: "",
      gratuito: "",
    },
  }),
  mutations: {
    setCursos(state, cursos) {
      if (Object.keys(cursos).length === 0) {
        state.cursos = cursos
      } else {
        state.cursos = cursos.reduce(function (objeto, index) {
          objeto[`${index.cod_centro}-${index.cod_curso}`] = index.nom_curso;
          return objeto;
        }, {});
      }
    },
    setCurso(state, curso) {
      state.curso = curso
    },
    setDetalhesDoCurso(state, detalhesDoCurso) {
      state.detalhesDoCurso = detalhesDoCurso;
    },
  },
  getters: {
    getCursos(state) {
      return state.cursos
    },
    getCurso(state) {
      return state.curso
    },
    getDetalhesDoCurso(state) {
      return state.detalhesDoCurso
    },

  }
}
