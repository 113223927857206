import { required, helpers } from "@vuelidate/validators"

export function CursoValidation() {
  var object = {};

  object["nivelValor"] = {
    required: helpers.withMessage("Selecione o Nível", required),
  }
  object["campusModel"] = {
    required: helpers.withMessage("Selecione o Campus", required),
  }
  object["cursoModel"] = {
    required: helpers.withMessage("Selecione o Curso", required),
  }
  object["turma"] = {
    required: helpers.withMessage("Selecione a Turma", required),
  }
  object["ex_aluno"] = {
    required: helpers.withMessage("Selecione o Vínculo", required),
  }

  return object;
}