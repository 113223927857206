import $EADClass from "./EADClass.js"
import $PreClass from "./PreClass.js"
import $EADGratuito from "./EADGratuito.js"
import $POS from "./POS.js"
import $ExtPre from "./ExtPre.js"

export default {
  EADC: $EADClass,
  EAD: $EADGratuito,
  POS: $POS,
  PRECLASS:$PreClass,
  EXTPRE:$ExtPre
}
